import React, { Component } from "react"
import classNames from "classnames"
import fbRecommendations from "./fb-recommendations.svg"
import Stars from "./Stars"
import PropTypes from "prop-types"

const SocialReviews = ({
  google,
  facebook,
  googleHeading,
  language = "en"
}) => {
  let featuredSocialClasses = classNames({
    "featured-social": true,
    headless: !googleHeading
  })

  return (
    <>
      {google && (
        <div className="social-reviews">
          <div className={featuredSocialClasses}>
            {googleHeading && (
              <a
                className="link-wrapper"
                href={google.url}
                target="_blank"
                rel="noopener noreferrer">
                <h3>Google</h3>
              </a>
            )}
            <a
              className="link-wrapper"
              href={google.url}
              target="_blank"
              rel="noopener noreferrer">
              <div className="review-icons">
                <h4 className="google-rating">{google.rating.toFixed(1)}</h4>
                <Stars rating={google.rating} />
              </div>
            </a>
            <a className="review-number-text" href={google.url} target="_blank">
              {google.totalReviews} {language === "es" ? "Reseñas" : "Reviews"}
            </a>
          </div>
        </div>
      )}

      {facebook && (
        <div className="social-reviews">
          <div className="featured-social">
            <a
              className="link-wrapper"
              href={facebook.url}
              target="_blank"
              rel="noopener noreferrer">
              <h3>Facebook</h3>
            </a>
            <a
              className="link-wrapper"
              href={facebook.url}
              target="_blank"
              rel="noopener noreferrer">
              <div className="review-icons">
                <div className="svg-wrapper">
                  <img src={fbRecommendations} alt="Facebook Reviews" />
                </div>
              </div>
            </a>
            <a
              className="review-number-text"
              href={facebook.url}
              target="_blank">
              {facebook.totalReviews}{" "}
              {language === "es" ? "Reseñas" : "Reviews"}
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default SocialReviews
